import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'

Vue.config.productionTip = false

Vue.filter('toCurrency', function (value) {
	// console.log("incoming: ", value);
	const outgoing = value.toFixed(2).toLocaleString('en-US', { style: 'currency', useGrouping: true });
	// console.log("outging: ", outgoing);
	return "$" + outgoing;
});

new Vue({
	router,
	render: function (h) { return h(App) }
}).$mount('#app')
