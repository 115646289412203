<template>
	<div class="page">
		<div v-if="showOverlay" class="overlay">
			<b style="font-size:40px">Easy-Claim</b><br />The easiest way to get the most for your<br />Insurance Property Claim!
			<hr style="border: 1px dotted silver" />
			All you need to do is search for the items to be replaced, add them to your list and Easy-Claim does the rest.
			<hr style="border: 1px dotted silver" />
			Easy-Claim will find and document the prices on eBay that give you the most value for your claim, and will even generate the paperwork
			you'll need to support your claim with the Insurance company!
			<hr style="border: 1px dotted silver" />
			And it's FREE and EASY!<br />
			<hr style="border: 1px dotted silver" />
			<button @click="dismissOverlay()" class='start-button'>Start my claim!</button>
			<div class="spacer"></div>
		</div>
		<div class="header">Easy-Claim!</div>
		<div class="info-box">
			<div class="label">Claim Value:</div>
			<div class="total">{{ total | toCurrency }}</div>
		</div>
		<div class="scan" @click="scanBarcode()">
			Tap to scan a Barcode, UPC, ISBN, QR Code, etc. or enter a description below
		</div>
		<input
			id="input-text"
			autofocus
			ref="query"
			class="input-text"
			type="text"
			v-model="keywords"
			placeholder="Title, description, keywords, author, tags..."
			v-on:keyup.enter="search()"
		/>
		<button
			@click="search()"
			class="search"
		>
			Search
		</button>
		<div class="intro" v-if="searchingText.length == 0 && items.length == 0">
			<hr />
			Easy-Claim gets you the most $$$ for your insurance property claim!
			<hr />
			Use the keyword or barcode search to find replacement items on eBay.
			<hr />
			Add items to your claim and Easy-Claim includes the pricing documentation
			you'll need to get the most for your claim!
			<hr />
		</div>
		<div class="searching" v-if="searchingText.length > 0">
			{{ searchingText }}
		</div>
		<div class="results-box">
			<div
				class="item"
				v-for="(item, idx) in items"
				v-bind:key="idx"
				:class="{ selected: idx == selectedItem }"
				@click="selectItem(idx)"
			>
				<table width="96%">
					<tr class="table-row">
						<td class="image-box">
							<img class="item-image" :src="item.imageURL" />
						</td>
						<td class="item-title">
							{{ item.title }}
						</td>
						<td class="item-price">
							{{ item.price | toCurrency }}
						</td>
					</tr>
				</table>
				<div class="item-url">
					<a class="item-url" target="_blank" :href="item.partnerURL">{{
						item.url
					}}</a>
				</div>
			</div>
		</div>
		<div class="spacer"></div>
		<div class="footer">
			<button
				class="bottom-button"
				:class="{ disabled: claim.length == 0 }"
				@click="viewClaim()"
			>
				View Claim
			</button>
			<button
				class="bottom-button"
				:class="{ disabled: selectedItem == -1 }"
				@click="addItem()"
			>
				Add Item
			</button>
		</div>
	</div>
</template>

<script>
import axios from 'axios';
export default {
	name: 'Home',
	data() {
		return {
			claim: [],
			items: [],
			total: 0,
			selectedItem: -1,
			keywords: "",
			searchingText: "",
			showOverlay: true
		}
	},
	mounted() {
		this.loadData();
		this.$refs.query.focus();
		this.$refs.query.scrollIntoView();
	},
	methods: {
		loadData: function () {
			console.log("loading...")
			var claim = JSON.parse(localStorage.getItem('claim'));
			if (claim) {
				this.showOverlay = false;
				this.claim = claim;
				this.total = this.claim.reduce((sum, cur) => sum + cur.price, 0)
			} else {
				this.claim = [];
			}
			let query = this.$route.query.q;
			let type = this.$route.query.type;
			if (query && query.length > 1) {
				console.log("Search for", type, query);
				this.keywords = query;
				this.search();
			}
		},

		dismissOverlay: function () {
			this.showOverlay = false;
		},
		scanBarcode: function () {
			this.$router.push("/scan")
		},

		search: async function () {
			if (this.keywords && this.keywords.length > 1) {
				const apiBaseURL = "https://us-central1-easy-claim-281b5.cloudfunctions.net/api/easyclaim/"
				// const apiBaseURL = "http://localhost:5001/easy-claim-281b5/us-central1/api/easyclaim/";
				console.log("Searching API NOW for " + this.keywords, "at", apiBaseURL);
				this.searchingText = `Searching for ${this.keywords}...`;
				this.selectedItem = -1;
				this.items = [];
				let url = `${apiBaseURL}search?q=${encodeURI(this.keywords)}`;
				axios.get(url, { timeout: 10000 }).then(res => {
					this.items = res.data;
					if (this.items.length > 0) {
						this.items.sort((a, b) => { return a.price < b.price ? 1 : -1 })
						this.searchingText = "";
						this.keywords = "";
					} else {
						console.log("No results")
						this.searchingText = "No results found for " + this.keywords;
					}
				})
					.catch(err => {
						console.log("Got back error", err);
						this.searchingText = "No results found for " + this.keywords;
						this.searchingText += " --- " + err;
					})
			} else {
				this.searchingText = "Longer keyword required"
			}
			this.$refs.query.focus();
			this.$refs.query.scrollIntoView();
		},

		clearSearch: async function () {
			this.selectedItem = -1;
			this.keywords = '';
			this.items = [];
			this.$refs.query.focus();
			this.$refs.query.scrollIntoView();
		},

		addItem: function () {
			this.total = this.total + this.items[this.selectedItem].price;
			this.claim.push(this.items[this.selectedItem]);
			this.claim = this.claim.sort((a, b) => { return a.price < b.price ? 1 : -1 });
			// console.log("Sorted", JSON.stringify(this.claim));
			localStorage.setItem('claim', JSON.stringify(this.claim));
			this.clearSearch();
		},

		viewClaim: function () {
			this.$router.push("/claim")
		},

		selectItem: function (idx) {
			this.selectedItem = idx;
		}
	}
}
</script>

<style scoped>
.overlay {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 5;
	color: white;
	font-size: 20px;
	background-color: rgba(0, 0, 0, 0.88);
	text-align: center;
}
.scan {
	margin: 10px;
	border: 2px dashed #00993b;
	border-radius: 10px;
	padding: 10px;
	text-align: center;
}
.input-text {
	border: 1px solid black;
	border-radius: 3px;
	padding: 5px;
	width: 92%;
	font-size: 20px;
	margin-bottom: 10px;
}
.intro {
	width: 92%;
	margin: 0px 5px 0px 5px;
	text-align: center;
	font-size: 16px;
	padding: 3px;
	/* border: 1px solid black;
	border-radius: 5px; */
}

.start-button {
	width: 96%;
	background-color: #00993b;
	color: #f5faf7;
	text-align: center;
	font-size: 26px;
	padding: 10px 0px 10px 0px;
	border-radius: 5px;
	margin: 2px;
}

.search {
	min-width: 31%;
	background-color: #00993b;
	color: white;
	text-align: center;
	font-size: 26px;
	padding: 8px 4px 8px 4px;
	border-radius: 5px;
}
.searching {
	margin: 10px;
	border: 2px dotted #00993b;
	border-radius: 10px;
	padding: 10px;
	text-align: center;
}
.clear-search {
	width: 99%;
	background-color: maroon;
	color: white;
	text-align: center;
	font-size: 26px;
	padding: 10px;
	border-radius: 5px;
}
</style>