import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/claim',
    name: 'Claim',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Claim.vue')
    }
  },
  {
    path: '/scan',
    name: 'Scan',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Scan.vue')
    }
  },
  {
    path: '/print',
    name: 'Print',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Print.vue')
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
